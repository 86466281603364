<template>
	<el-dialog :visible="visible" title="用户挂号设置" @close='close()' width="1300px" style="margin-top:-93px!important;">
		<el-tabs v-model="tabActive">

			<el-tab-pane label="本周" name="first">
				<el-form ref="form" :model="form" label-width="200px" style="height: 500px; margin-top: -46px; ">

					<div class="tableBox" style="height: 500px;">


						<el-table :data="froms.tableDataNow" height="500" border style="width: 100%">



							<el-table-column prop="date" label="日期" width="100">
								<template slot="header">
									<div style="display: flex; justify-content: space-between">
										<span>日期</span> <span>时间</span>
									</div>
								</template>
								<template slot-scope="scope">
									<div slot="reference" style="text-align: center;">
										{{ scope.row.week_name }}
										<br />
										{{ scope.row.week_time }}
									</div>
								</template>
							</el-table-column>


							<el-table-column prop="name" style="text-align: center;" label="上午">
								<template slot-scope="scope">
									<div slot="reference" style="text-align: center;">

										<div class="news left" v-for="(item,i)  in  scope.row.date_v_model">
											<span style="float: left;width: 130px;">




												<el-radio  v-model="checkedList"
										
												
												
													:label="scope.row.week_time+' '+scope.row.date_v_model[i]+':00'"
													v-if="scope.row.date_v_model[i].indexOf(':') != -1" name="times">
													{{scope.row.date_v_model[i]}}</el-radio>







											</span>
										</div>

									</div>

								</template>


							</el-table-column>


							<el-table-column prop="address" label="下午">

								<template slot-scope="scope">

									<div slot="reference" style="text-align: center;">
										<div class="news left" v-for="(item,i)  in  scope.row.date_v_model_2">
											<span style="float: left;width: 130px;">

												<el-radio 
												
												:label="scope.row.date_v_model_2[i]"
													v-if="scope.row.date_v_model_2[i].indexOf(':') != -1" name="times">
												</el-radio>


												<!-- 		<el-time-picker style="width: 120px;" value-format="HH:mm"
													format="HH:mm" v-model="scope.row.date_v_model_2[i]"
													:picker-options="{
																		  selectableRange: '13:00:00 - 17:00:00'
																		}" placeholder="">
												</el-time-picker> -->




											</span>
										</div>
									</div>


								</template>


							</el-table-column>




						</el-table>
					</div>
					
					
					
					<!-- 
					<div class="line" style=" background-color: #EBEEF5; margin-top: 7%; margin-left: 2%;"
						:style="{width:lineWhide+'px',transform:'rotate(21deg)'}">
					</div> -->
					
					


				</el-form>
			</el-tab-pane>

			<el-tab-pane label="下周" name=" ">
				<el-form ref="form" :model="form" label-width="200px" style="height: 500px; margin-top: -46px;  ">
					<div class="tableBox" style="height: 500px;">
						<el-table :data="froms.tableDataNext" height="500" border style="width: 100%">
							<el-table-column prop="date" label="日期" width="100">
								<template slot="header">
									<div style="display: flex; justify-content: space-between">
										<span>1日期</span> <span>时间</span>
									</div>
								</template>
								<template slot-scope="scope">
									<div slot="reference" style="text-align: center;">
										{{ scope.row.week_name }}
										<br />
										{{ scope.row.week_time }}
									</div>
								</template>


							</el-table-column>


							<el-table-column prop="name" style="text-align: center;" label="上午">
								<template slot-scope="scope">
									<div slot="reference" style="text-align: center;">
										<div class="news left" v-for="(item,i)  in  scope.row.date_v_model">
											<span style="float: left;width: 130px;">



												<el-time-picker style="width: 120px;" value-format="HH:mm"
													format="HH:mm" v-model="scope.row.date_v_model[i]" :picker-options="{
																		  selectableRange: '13:00:00 - 17:00:00'
																		}" placeholder="">
												</el-time-picker>




											</span>
										</div>
									</div>
								</template>


							</el-table-column>


							<el-table-column prop="address" label="下午">

								<template slot-scope="scope">
									<div slot="reference" style="text-align: center;">
										<div class="news left" v-for="(item,i)  in  scope.row.date_v_model_2">
											<span style="float: left;width: 130px;">
												<!--  {{scope.row.date_v_model_2[i]}} -->

												<el-time-picker style="width: 120px;" value-format="HH:mm"
													format="HH:mm" v-model="scope.row.date_v_model_2[i]"
													:picker-options="{
																	  selectableRange: '13:00:00 - 17:00:00'
																	}" placeholder="">
												</el-time-picker>




											</span>
										</div>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>


					<div class="line" style=" background-color: #EBEEF5; margin-top: 7%; margin-left: 2%;"
						:style="{width:lineWhide+'px',transform:'rotate(21deg)'}">
					</div>
				</el-form>
			</el-tab-pane>

		</el-tabs>


		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onSubmit">保存</el-button>
		</span>

	</el-dialog>
</template>

<script>
	import $ from "jquery"
	import axios from "axios";
	import MyDialog from "../../../../components/MyDialog";

	export default {
		name: 'SetTtreatmentTimeDialog',
		components: {
			MyDialog
		},
		data() {
			return {
				arsenal: '',
				timeaab: '',
				timeaac: '',
				timeaad: '',

				value: '',
				value2: '',
				value3: '',
				value4: '',
				tabActive: 'first',
				times: '',
				visible: false,
				froms: {
					tableDataNow: [],
					tableDataNext: [],
				},
				obj: {},
				did: 0,
				checkedList: [],

			}
		},
		created() {
 

			// const params = new URLSearchParams();
			// params.append('uid', sessionStorage.getItem('uid'));
			// console.log(params);
			// axios.post(this.apiUrl + '/api/doctor/getVisitDate', params)
			// 	.then((response) => {
			// 		console.log(response.data.data);
			// 		this.froms.tableDataNow = response.data.data.nowDate;
			// 		this.froms.tableDataNext = response.data.data.nextDate;
			// 	})
			// 	.catch(function(error) {
			// 		console.log(error);
			// 	});

		},
		mounted: function() {
			this.$nextTick(function() {
				$('.time').clockTimePicker({});
			})
		},

		methods: {
			onSubmit() {
				console.log("/////////////////////////");
				console.log(this.checkedList);
				console.log("/////////////////////////");

				// alert(this.froms.tableDataNow[0].date_v_model.keys0);


				const params3 = new URLSearchParams();

				params3.append('uid', sessionStorage.getItem('uid'));
				params3.append('dct_id', this.did);
				params3.append('datas', this.checkedList);

				axios.post(this.apiUrl + '/api/doctor/setVisitDateDoctor', params3)
					.then((response) => {
						this.visible = false;
						alert('操作成功')

					})
					.catch(function(error) {
						console.log(error);
					});


				// console.log("////////////////");
				// console.log(this.froms.tableDataNow[0]);
				// const formData = new FormData()
				// //这里是把上传接口所有的参数都append到formData中
				// var now = JSON.stringify(this.froms.tableDataNow);
				// var next = JSON.stringify(this.froms.tableDataNext);
				// formData.append('now', now)
				// formData.append('next', next)
				// formData.append('uid', sessionStorage.getItem('uid'))
				// formData.append('dct_id', this.did)
				// const config = {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data'
				// 	}
				// }

				// axios.post(this.apiUrl + '/api/doctor/setVisitDate', formData, config).then((res) => {
				// 	this.homeCities = res.data.data;
				// })



			},

			show(did) {
				// this.did = did;

				// const params2 = new URLSearchParams();
				// params2.append('uid', sessionStorage.getItem('uid')); 
				// params2.append('did', this.did); 

				// axios.post(this.apiUrl+'/api/doctor/getVisitDateValue',params2)
				// .then((response) => {   
				// 	this.froms.tableDataNow = response.data.data.nowDate; 
				// 	this.froms.tableDataNext = response.data.data.nextDate;  
				// }) 
				// .catch(function(error) {
				//   console.log(error);
				// }); 

				this.did = did;

				const params2 = new URLSearchParams();
				params2.append('uid', sessionStorage.getItem('uid'));
				params2.append('did', this.did);

				axios.post(this.apiUrl + '/api/doctor/getVisitDateValue', params2)
					.then((response) => {
						this.froms.tableDataNow = response.data.data.nowDate;
						this.froms.tableDataNext = response.data.data.nextDate;
					})
					.catch(function(error) {
						console.log(error);
					});



				this.visible = true;
			},

			close() {
				this.visible = false;
			}
		}
	}
</script>


<style scoped lang="scss">
	.tableBox {
		margin-top: 50px;
		position: relative;
		/* background-color: red; */
	}

	.line {
		position: absolute;
		/* position:fixed; */
		z-index: 9;
		top: 0;
		left: 0;
		height: 1px;
		width: 110px;
		background-color: black;
		transform-origin: left;
		transform: rotate(24.2deg);
	}

	.el-slider {
		margin-top: 40px;
	}

	/deep/ .el-slider__marks-text {
		font-size: 12px;
		width: 44px;
	}

	.tips {
		color: $red;
		text-align: right;
		font-size: 12px;
		margin-top: 20px;
	}

	.text {
		margin: 40px 20px;
		font-weight: 500;
	}

	.button {
		text-align: center;

		.el-button {
			min-width: 120px;
		}
	}

	.content {
		height: 100px;
		margin: 20px;
		border: 2px solid $red;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background: #FEF6F4;
		color: $red;
		padding: 20px;

	}
</style>
