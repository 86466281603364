<template>
  <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="500px"
  >
  
        <el-input 
		   type="password"
           :rows="2"
           placeholder="原始密码"
           v-model="pwd"
           style="margin-top: 10px"
       /> 
	   
       <el-input  
		   type="password"
           :rows="2"
           placeholder="请输入新密码"
           v-model="newpwd"
           style="margin-top: 10px"
       />    
	   <el-input 
		   type="password"
           :rows="2"
           placeholder="确认新密码"
           v-model="newpwd2"
           style="margin-top: 10px"
       />       
	
		<span slot="footer" class="dialog-footer">
		  <el-button type="primary" @click="handleSend2">保存</el-button>
		</span> 
	
  </el-dialog>
</template>


<script>
	import axios from "axios";
  export default {
    name: "ChangePwd",
    props: ['info'],
    data() {
      return {
		pwd:'',
		newpwd:'', 
		newpwd2:'',  
        dialogVisible: false,
		id:0, 
		finfo:{},
		rules: { 
		  telphone: [
		    {required: true, message: "手机号码不能为空", trigger: 'blur'}
		  ],
		  smsVerificationCode: [
		    {required: true, message: "短信验证码不能为空", trigger: 'blur'}
		  ]
		}
      }
    },
	 watch: {
	      info: function(newVal,oldVal){
	        this.finfo = newVal; //newVal即是chartData
	        this.drawChart();
	      }
	    },
	methods: {
	  open() {
	    this.dialogVisible = true
	  },
	  handleSend2(){
		  
		if(this.newpwd != this.newpwd2){ 
			this.$message.error('确认密码有误');
		}else{
			const params = new URLSearchParams();
			params.append('uid', sessionStorage.getItem('uid'));  
				 
			params.append('newpwd', this.newpwd);  
			params.append('pwd', this.pwd); 
				 
			
			axios.post(this.apiUrl+'/api/user/setChangePwd', params)
			.then((response) => { 	 
						  if(response.data.code==3){
							  	this.$message.error('原始密码错误');
						  }else{
							  
							  		  		this.$message.success('操作成功');
							  		  		    
						  }
			  })
			  .catch(function(error) {
			    console.log(error);
			  });
		}
		 
		  
		  		
	  }
	}
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
