<template>
  <el-dialog
      title="网站使用说明"
      :visible.sync="dialogVisible"
      width="1000px"
	   
  >
  
         <div v-html="info" ></div> 
	 
	  
	
  </el-dialog>
</template>


<script>
	import axios from "axios";
  export default {
    name: "Explain",
    props: ['info'],
    data() {
      return {  
        dialogVisible: false,
		info:''
		 
      }
    },
	created(){ 
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem('uid')); 
	 
		axios.post(this.apiUrl+'/api/user/getExplain',params)
		.then((response) => {   
			this.info = response.data.data; 
			 
		}) 
		.catch(function(error) {
		  console.log(error);
		});
	},
	
	 // watch: {
	 //      info: function(newVal,oldVal){
	 //        this.finfo = newVal; //newVal即是chartData
	 //        this.drawChart();
	 //      }
	 //    },

	methods: {
	  open() {
	    this.dialogVisible = true
	  },
	 
	   
	}
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
