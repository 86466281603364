<template>
  <el-dialog
      :title="title"
      :visible.sync="visible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="cancel"
      :width="width">
    <slot></slot>
    <div slot="footer" class="dialog-footer" v-if="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'MyDialog',
    props: {
      title: String,
      visible: Boolean,
      width: {
        type: String,
        default: '600px'
      },
      footer: {
        type : Boolean,
        default : false,
      }
    },
    methods: {
      // 取消的操作
      cancel() {
        this.$emit('cancel')
      },

      // 确定的操作
      submit() {
        this.$emit('submit');
      }
    }
  }
</script>

<style scoped lang="scss">

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
  .el-dialog__title{
    line-height: 33px;
    font-size: 12px;
    color: #fff;
  }
  .el-dialog__headerbtn{
    top: 8px;
  .el-dialog__close{
    color: #fff;
  }
  }
  }
  /deep/ .el-dialog__body{
    padding: 6px 26px;
    min-height: 200px;
  }

</style>
