<template>
  <el-dialog
      title="手机号修改"
      :visible.sync="dialogVisible"
      width="500px"
  >
  
        <el-input 
			type="text"
           :rows="2"
           placeholder="请输入新手机号"
           v-model="tel"
           style="margin-top: 10px"
       /> 
	   
       <el-input 
	   type="text"
           :rows="2"
           placeholder="1请输入新"
           v-model="code"
		   
           style="width:230px; margin-top: 10px;"
       />   &nbsp;&nbsp;&nbsp; 
	   <el-button type="primary" @click="sendSMSCaptcha">发送短信</el-button>
	   
	<!--   <el-form ref="form" :rules="rules" :model="form" label-width="120px">
		 
		   <el-form-item label="短信验证码" prop="smsVerificationCode">
			 <el-row :gutter="10" class="captcha">
			   <el-col :span="10" class="text-input">
				 <el-input v-model="form.smsVerificationCode"></el-input>
			   </el-col>
			   <el-col :span="6" class="text-captcha">
				 <el-button :disabled="btnSMSText !== btnSMSDefText" @click="sendSMSCaptcha">{{btnSMSText}}
				 </el-button>
			   </el-col>
			 </el-row>
		   </el-form-item>  
	 
	 	<span slot="footer" class="dialog-footer">
		  <el-button type="primary" @click="handleSend2">发送</el-button>
		</span> 
	
	</el-form>
	-->
	
	
		<span slot="footer" class="dialog-footer">
		  <el-button type="primary" @click="handleSend2">保存</el-button>
		</span> 
	
  </el-dialog>
</template>


<script>
	import axios from "axios";
  export default {
    name: "ChangePhone",
    props: ['info'],
    data() {
      return {
		  code:'',
		  tel:'',
		btnSMSText: "发送验证码",
		btnSMSDefText: "发送验证码",
		
        dialogVisible: false,
		id:0,
		textarea:'',
		finfo:{},
		rules: { 
		  telphone: [
		    {required: true, message: "手机号码不能为空", trigger: 'blur'}
		  ],
		  smsVerificationCode: [
		    {required: true, message: "短信验证码不能为空", trigger: 'blur'}
		  ]
		}
      }
    },
	 watch: {
	      info: function(newVal,oldVal){
	        this.finfo = newVal; //newVal即是chartData
	        this.drawChart();
	      }
	    },

	methods: {
	  open() {
	    this.dialogVisible = true
	  },
	  sendSMSCaptcha() {
		   
	    if (this.tel) {
	  			  // this.captchaCountDown();  
	  			  
	  			  const params2 = new URLSearchParams();
	  			  params2.append('phone', this.tel);  
	  			  		 
	  			  axios.post(this.apiUrl+'/api/login/sendMess',params2)
	  			  .then((response) => {  
	  				this.$message.alert('发送成功') 
	  			  }) 
	  			   .catch(function(error) {
	  			      console.log(error);
	  			  }); 
	  			  
	      // sendSMSValifitaionCode(this.form.telphone).then(res => {
	      //   this.captchaCountDown();
	      //   this.$message.alert('发送成功')
	      // })
	    } else {
	      this.$message.error('请先输入手机号码')
	    }
	  },
	  handleSend2(){
		 
		  const params = new URLSearchParams();
		  params.append('uid', sessionStorage.getItem('uid')); 
	 
		  params.append('tel', this.tel); 
	 
		  params.append('code', this.code); 
	 
		  
		  axios.post(this.apiUrl+'/api/user/setChangePhone', params)
		  .then((response) => { 	
		  		this.$message.success('操作成功');
		  		     window.location.reload();	
		    })
		    .catch(function(error) {
		      console.log(error);
		    });
		  		
	  }
	}
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
