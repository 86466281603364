<template>
  <el-card>
    <div slot="header">
      <h3>网站使用</h3>
    </div>
	<div>
	  <el-button type="primary" @click="changePhone">更换手机号</el-button>
	  <el-button type="primary" @click="changePwd">修改密码</el-button>
	  <el-button type="primary" @click="goSetCancellation">注销账号</el-button>
	  <el-button type="primary" @click="goExplain">网站使用说明</el-button>
	  <el-button type="primary" @click="setTtreatmentTime" >预约咨询设置</el-button>
	  <el-button type="primary" @click="goFaPiao" >发票设置</el-button>
	
	
<!-- 	  <change-phone-dialog ref="changePhone"></change-phone-dialog>
	  <set-visitor-dialog ref="setVisitor"></set-visitor-dialog>
	  <set-duration-dialog ref="setDuration"></set-duration-dialog> -->
    <ChangePhone ref="ChangePhone" :info="info" /> 
    <ChangePwd ref="ChangePwd" :info="info" />
    <Explain ref="Explain" :info="info" />
      <set-ttreatment-time-dialog ref="setTtreatmentTime"></set-ttreatment-time-dialog>
	
	</div>
	
	
	<ChatRoomAddCaseRecordPatient ref="ChatRoomAddCaseRecordPatient" />
		<ChatRoomAddCaseRecord ref="ChatRoomAddCaseRecord"  />
		
		
  </el-card>
  
</template>

<script>
	import axios from "axios";
  import ChangePwd from '@/components/ChangePwd.vue'
      import ChangePhone from '@/components/ChangePhone' 
      import Explain from '@/components/Explain' 
  import SetTtreatmentTimeDialog from "../service/components/SetUserTtreatmentTimeDialog.vue";
  
  
  import ChatRoomAddCaseRecord from '@/components/ChatRoomAddCaseRecord'
   
  
  import ChatRoomAddCaseRecordPatient from '@/components/ChatRoomAddCaseRecordPatient'
  
  
  export default {
    name: "UsageIndex", 
    components: {ChangePhone,ChangePwd,Explain,SetTtreatmentTimeDialog,ChatRoomAddCaseRecordPatient,ChatRoomAddCaseRecord,}, 
    data() {
      return {}
    }
	,
	methods: { 
		goFaPiao(id){
				 
						this.$refs.ChatRoomAddCaseRecordPatient.open(id);
					 //this.$refs.ChatRoomAddCaseRecord.open(1, 1); 
		},
		 /**
		 * 看病预约设置
		 */
		setTtreatmentTime(){  
			
			this.$refs['setTtreatmentTime'].show();
		},
		//网站 说明 
		goExplain(){  
			this.$refs.Explain.open() 
		},
		
	    goSetCancellation(){
				this.$confirm('注销登录将无法登陆, 是否确定?', '提示', {
		            confirmButtonText: '确定',
		            cancelButtonText: '取消',
		            type: 'warning'
		          }).then(() => {
		            console.log(111);
					const params = new URLSearchParams();
					params.append('uid', sessionStorage.getItem('uid'));  
					
					axios.post(this.apiUrl+'/api/user/setCancellation', params)
					.then((response) => { 	 
						 this.$message.success('操作成功');
					})
					.catch(function(error) {
					    console.log(error);
					});
		          }).catch(() => {
		            this.$message({
		              type: 'info',
		              message: '已取消操作'
		            });
		          });
				  
		  
		  
	  },
	  changePwd() { 
		this.$refs.ChangePwd.open() 
      },
	  changePhone() { 
		this.$refs.ChangePhone.open() 
      }, 
	}
  }
</script>

<style scoped lang="scss">
  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        padding: 0;
        margin: 0;
      }
    }
  }
</style>
